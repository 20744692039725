 .seccion_a{
    display: flex;
    height: 600px;
    justify-content: space-between;
    background-image: url(../../assets/fotohome.webp);
    background-position:center;
    background-size: cover;
  } 
  .container_uno{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } 
  .a_container{
   height: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 4px;
  }
  .title_a{
    color: #7ED957;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }
  .title_a_2{
    color:#4148c8;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }
  .b_container{
  width: 60%;
  margin-top: 20px;
  }
  .b_container h2{
  color: white;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 25px;
  }
  .b_container p{
    color: white;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    
  }
  .contact_seccion{
    width: 100%;
    margin-top: 5%;
  }
  .btn_contact{
    background-color:#ED7979;
    padding: 5px;
    position: relative;
    border: none;
    width: 150px;
    height: 40px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    transition: 0.5s;
    font-size: 17px;
  }
  
  .btn-contact-padding{
    padding: 10px 20px;
  }
  button p {
    position: absolute;
    top: 0.4em;
    left: 1.15em;
    margin: 0;
    padding: 0;
    transition: .5s;
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
  
  button svg {
    position: absolute;
    top: 0.45em;
    right: 0.5em;
    margin: 0;
    padding: 0;
    transition: 0.5s;
    height: 1em;
    fill: #fff
  }
  
  button:hover p {
    left: 0.5em;
    color: #fff
  }
  
  button:hover svg {
    opacity: 1;
  }
  
  button:hover {
    background-color:  #7ED957
  };

  .img_logo{
  width: 350px;
  border-radius: 50%;
  }
  .seccion_b{
  width: 100%;
  margin-bottom: 10%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8%;
  }
  .seccion_b h1{
  font-size: 25px;
  color: #7ED957;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  }
  .img_italy{
    width: 200px;
    height: 10px;
  }
  .clientes h2 {
    color: #7ED957;
    font-size: 21px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }
  
  .seccion_b_1{
  width: 60%;
  height: 100%;
  display: flex;
  margin-top: 5%;
  align-items: flex-start;
  }
  .seccion_b_1 h1{
  color: #7ED957;
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
  margin-top: 3%;
  }
  .text_obj{
  color: white;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  width: 80%;
  }
  .objetivos{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  }


  
  @media (min-width: 577px) and (max-width: 1400px) {
    .seccion_a{
      height: 800px;
    } 
    
}

  @media (max-width: 576px) {

    
    .menu_nav {
    border: none;
    }
    .img_italy{
    width: 300px;
    }
    .container_uno {
      width: 100%;
      padding: 20px;
      text-align: center;
    }
    
    .container_dos {
      background-repeat: no-repeat;
      width: 100%;
      margin-top: 20px;
      height: 100%;
      background-size: cover;
      background-position: inherit;
    }
    .seccion_a{
    flex-direction: column;
    height: 650px;
    }
    .title_a{
    font-size: 25px;
    }
    .title_a_2{
    font-size: 25px;
    }
    .b_container{
    width: 100%;
    }
    .b_container h2{
    font-size: 20px;
    }
    .btn_contact {
      display: none;
    }
    
    .seccion_b{
    width: 100%;
    }
    
    .seccion_b_1 {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 10%;
    }
    
    .seccion_b_1 h1 {
      margin-top: 0;
    }
    
    .carousel__elemento {
      margin-bottom: 30px;
    }
    .clientes{
      margin-top: 5%;
    }
    
    .card {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;
    }

    .contenido_title {
      text-align: center;
    }
    
    .contenido_title img {
      margin: 10px auto;
      width:200px;
    }
    
    .servicios {
      margin-top: 20px;
    }
    
    .card {
      margin-bottom: 20px;
    }
    
    .read_button {
      margin-top: 10px;
    }
    
    .footer_footer {
      height: auto;
    }
  }  
 

