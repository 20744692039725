.noticia_uno{
    height: 700px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.titles_uno{
 width: 50%;
 margin-left: 5%;
}
.titles_uno h2{
    margin-left: 3%;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}
.carrousel_container{
    width: 60%;
    margin-top: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.noticia_dos{
    display: flex;
    flex-direction: column;
    height: 700px;
    align-items: center;
    justify-content: center;
}
.titles_dos{
    text-align: center;
}
.titles_dos h2{
    margin-left: 3%;
    width: 100%;
    color: #7ED957;
    font-size: 23px;
    font-family: 'Montserrat', sans-serif;
}

.img_italy{
    width: 300px;
}
.img_eventos{
    width:250px;
    height:250px;
}

.eventos_photo{
    display: flex;
    width: 100%;
    gap: 5%;
    margin-top: 3%;
    align-items: center;
    justify-content: center;
}
@media (max-width: 576px) {
.noticia_uno{
    flex-direction: column;
    height: 600px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5%;

}
.titles_uno{
    width: 100%;
    text-align: center;
    margin-top: 5%;
 
}
.titles_uno h2{
    font-size: 17px;
}
.carrousel_container{
    width: 100%;
    text-align: center;
}

.noticia_dos{
    height: 1000px;
}
.eventos_photo{
    flex-wrap: wrap;
}
.titles_dos h2 {
    font-size: 17px;
}
.img_eventos {
    width: 200px;
    height: 200px;
    margin-bottom: 5%;
}
}
@media (min-width: 577px) and (max-width: 1200px) {
    .img_eventos {
        width: 230px;
        height: 200px;
    }
    .container_uno {
      width: 100%;
    }
    
}