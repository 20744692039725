.servicios_container {
  min-height: 850px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 5%;
}

.contenido_title{
  text-align: center;
  margin-top: 2%;
  margin-bottom:5%;
}
.contenido_title h2 {
  color: #7ED957;
  font-size:21px
}
.hvr-float {
display: inline-block;
vertical-align: middle;
-webkit-transform: perspective(1px) translateZ(0);
transform: perspective(1px) translateZ(0);
box-shadow: 0 0 1px rgba(0, 0, 0, 0);
-webkit-transition-duration: 0.3s;
transition-duration: 0.3s;
-webkit-transition-property: transform;
transition-property: transform;
-webkit-transition-timing-function: ease-out;
transition-timing-function: ease-out;
}
.hvr-float:hover, .hvr-float:focus, .hvr-float:active {
-webkit-transform: translateY(-8px);
transform: translateY(-8px);
}
.servicios {
  display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 35px;
    justify-items: center;
    justify-content: center;
    align-items: stretch;
    max-width: 1300px;
    width: 100%;

}
.iconos_container{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.btn_logo_banqueteria{
  color: #ED7979;
  margin: 3%;
  padding: 4%;
  background-color:rgb(235, 235, 235);
  border-radius: 20px;
}


.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 400px; 
  height: 500px;
  padding: 25px;
  background-color: white;
  text-align: justify;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.389);
  position: relative;
  align-items:center;
}

.card::before{
content: '';
position: absolute;
top: 0;
right: 0;
border: 20px solid transparent;
border-top: 20px solid #06d62e;
border-right: 20px solid #06d62e;
}

/* Icon */
.icon {
position: absolute;
top: -100px;
right: -20px;
}


.icon img {
width: 250px;
border-radius:50px
}

/* Content */
.content {
height: 215px;
overflow: hidden;
position: relative;
margin-top: 15%;
}

.content::before {
content: '';
position: absolute;
bottom: 0;
width: 100%;
height: 100px;

}

.content h3 {
text-align:center;
color:#ED7979;
font-size: 20px;
font-family: 'Montserrat', sans-serif;
font-weight: bold;
}
.content h2 {
text-align:in;
color:#ED7979;
font-size: 14px;
font-family: 'Montserrat', sans-serif;
font-weight: bold;
}
.content h3::before {
content: '';
position: absolute;
height: 2px;
width: 40px;
bottom: 4px;
border-radius: 100px;
}

.content p {
color:black;
font-size:13px;
font-family: 'Montserrat', sans-serif;
font-weight: bold;
}

/* Read More/Less Button */
.read_button {
border: none;
width:200px;
padding: 10px;
border-radius: 2px;
margin-top: 15px;
background-color:#06d62e;
border-radius:30px;
color: #313131;
font-weight: bold;
cursor: pointer;
font-size: 16px;
transition: all 0.2s ease-in-out;
}

.read_button:hover {
background-color: transparent;
color: #06d62e;
transition: all 0.2s ease-in-out;
}

/* Active */
.card.active{
height:600px;
}
.card.active .content {
height: auto;
}

.card.active .content::before {
visibility: hidden;
}

@media (max-width: 576px) {
  .servicios {
  display:flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .card {
    width: 90%;
  }
  .card.active {
      height: 900px;
  }
    

  .contenido_title {
    margin-top: 10%;
  }
  .contenido_title h2{
    font-size: 21px;
  }
  .icon{
  position:initial;
  }
  .icon img {
   position:initial;
    width: 100%;

    border-radius: 10px;
  }
  .content {
    height: auto;
  }

  .read_button {
    margin-top: 10px;
  }
}
@media (min-width: 577px) and (max-width: 1200px) {

  .card.active {
      height: 700px;
  }
    
.servicios_container{
  height:0%;
}   
.servicios {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10%;
  margin-top: 15%;
  margin-bottom: 25%;
}
.icon{
  position:initial;
  }
}