.container_container{
    height: 700px;
}
.img_italy{
    width:300px;
}
.container_title{
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items:center; 
    margin-top:2%;
}
.container_title h1 {
    color:#7ED957;
    font-family: 'Montserrat', sans-serif;
    font-size: 21px;
    margin-top:1%;
}
.logo_local{
    width:400px;
}
.punto_ventas{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top:3%;
}
.punto_uno {
    display: flex;
    gap: 20px;
    width: 600px;
    padding: 35px;
    background:black;
    border: 1px solid white;
}
.punto_uno h1{
    color:#7ED957;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
}
.punto_uno p{
    color:white;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight:bold;
}
.btn_logo_{
    color: white;
    margin: 3%;
    padding: 4%;
}
.btn_logo_:hover{
    transform: scale(2);
    color: #7ED957;
    background-color:white;
    border-radius: 80%;
    
}

@media (max-width: 576px) {
    .img_italy{
        width:200px;
    }
    .container_container {
        height: 800px;
    }
    .container_title {
      margin-top: 5%;

    }
   .img_calabaza{
    width:220px;
   }
    .container_title h1 {
        font-size: 20px;
      }
    .logo_local {
      width: 250px;
    }
    .punto_ventas{
    margin: 5%;
}
    .punto_uno {
      flex-direction:column;
      align-items: center;
      width: 100%;
    }
  }