.container_clientes {
    margin-top: 5%;
    height: 500px;
  }
  .clientes_card {
    margin-top: 3%;
  }
  .clientes {
    max-width: 1200px;
    height: 500px;
    width: 90%;
    margin: auto;
    text-align: center;
  }
  .contenido-principal {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
  } 
  .contenido-principal__imagen {
    vertical-align: top;
    margin-right: 20px;
    width: 50%;
    max-width: 550px;
  }
  
  .contenido-principal__clientes {
    width: 50%;
  }
  
  .contenido-principal__titulo {
    font-weight: normal;
    font-size: 28px;
  }
  
  .contenido-principal__resumen {
    font-family: 'Open Sans', sans-serif;
    line-height: 30px;
    color: #CFCFCF;
  }
  
  .carousel {
    margin-top: 5%;
  }
  
  .carousel__clientes {
    position: relative;
  }
  
  .carousel__anterior,
  .carousel__siguiente {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    border: none;
    top: calc(50% - 35px);
    cursor: pointer;
    line-height: 30px;
    text-align: center;
    background: none;
    color: #fff;
  }
  
  .carousel__anterior:hover,
  .carousel__siguiente:hover {
  }
  
  .carousel__anterior {
    left: -30px;
  }
  
  .carousel__siguiente {
    right: -30px;
  }
  
  .carousel__lista {
    overflow: hidden;
    width: 100%;
    display: flex;

}
  
  .glider-track {
    gap: 15px;
    display:flex;
  }
  
  .glider-slide img {
    border-radius: 50%;
    object-fit: cover;
  }
  
  .carousel__elemento {
    text-align: center;
  }
  
  .carousel__elemento p {
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
  
  .carousel__indicadores .glider-dot {
    width: 30px;
    height: 4px;
    background-color: #7ED957;
    opacity: .5;
    border-radius: 0;
  }
  
  .carousel__indicadores .glider-dot:hover {
    opacity: .9;
  }
  
  .carousel__indicadores .glider-dot.active {
    opacity: .8;
    background-color: #7ED957 ;
  }
  
