.footer{
    background-color:black;

}
.btn_logo{
    color: white;
    margin: 3%;
    padding: 1%;
}
.btn_logo:hover{
    transform: scale(2);
    color: #7ED957;
    background-color:white;
    border-radius: 80%;
    
}

.footer_footer{
flex-wrap:nowrap;
align-items:center;
flex-direction:column;
background-color: black;
}
.icon_footer{
    font-size:20px;
}
