.formulario{
  height: 800px;
  text-align: center;
  flex-direction: row;
  display: flex;
  align-items:flex-start;
  width: 100%;
  justify-content: center;
  margin-bottom: 3%;
}
.form_action{
  width:300px;
}
.formulario_container{
  max-width: 50%;
  margin-top: 5%;
  padding:15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 530px;
  border: 1px solid white;
  background: black;
}
.title_form {
  text-align: center;
  margin-top: 3%;
}
.title_form h1{
    color:#7ED957;
    font-family: 'Montserrat', sans-serif;
    font-size: 21px;
    margin-top:1%;
}
.info_contacto{
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5%;
  font-weight: bold;
  max-width: 50%;
  justify-content: center;
}
.info_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.mapa{
  width:500px;
  height:400px
}
.contacto{
  margin-top: 5%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  }
  
  .contacto h1 {
    text-align: center;
    color: #ffffff;
  }
  
  .contacto label {
    display: block;
    margin-bottom: 5px;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight:bold;
  }
  
  .contacto input[type="text"],
  .contacto input[type="email"],
  .contacto textarea {
    width: 100%;
    border: 1px;
    border-radius: 4px;
    background-color: #313131;
    color: white;
    opacity: 60%;
    border:1px solid white;
  }
  
  .contacto .error {
    color: #7ED957;
    font-size:12px;
    font-family: 'Montserrat', sans-serif;
    font-weight:bold;
  }
  
  .contacto button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: rgb(237, 121, 121);
    font-family: 'Montserrat', sans-serif;
    font-weight:bold;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contacto button[type="submit"]:hover {
    background-color: rgb(237, 121, 121);
  }

  .icon_contacto{
    display: flex;
    gap: 5%;
    width: 100%;
  }
.label_form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
}

@media (max-width: 576px) {
  .formulario {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .formulario_container {
    max-width: 100%;
    margin-top: 0;
    height: auto;
    }

  .title_form h1 {
    font-size: 20px;
  }

  .info_contacto {
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-top: 5%;
    max-width: 100%;
  }
  .info_container{
    margin:5%;
  }
.img_italy{
  width:250px;
}
  .mapa {
    width: 100%;
    height: 300px;
  }
  .contacto {
    max-width: 100%;
  }
}
.alert_message{
  color: #7ED957;
}
.error_envio_message{
  color:red;
}
@media (min-width: 577px) and (max-width: 992px) {
  .formulario {
    flex-direction: column;
    height: 1000px;
    align-items: center;
  }

  .formulario_container {
    max-width: 100%;
    margin-top: 0;
    height: auto;
  }
  .title_form {
    margin-bottom: 3%;
}
  .title_form h1 {
    font-size: 20px;
  }

  .info_contacto {
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-top: 5%;
    max-width: 100%;
    
  }

  .mapa {
    width: 400px;
    height: 300px;
  }

  .contacto {
    max-width: 100%;
  }
}
