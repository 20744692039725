.menu {
    background-color: black;
  }
  
  .menu_nav {
    display: flex;
    gap: 10px;
  }
  
  .menu_nav_a {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    text-decoration: none;
    border-bottom: 2px solid #7ED957;
  }
  
  .menu_nav_a:hover {
    color: aliceblue;
  }
  
  .menu_nav_a.active {
    color:  #7ED957;
  }
  
  .logo {
    border-radius: 50%;
    width:70px;
    height: 70px;
  }
  
  .btn_toggler {
    background-color: #7ED957;
  }
  

@media (max-width: 576px) {

    
    .menu_nav a{
    border: none;
    }

}

@media (min-width: 577px) and (max-width: 992px) {
    
    .menu_nav a{
        border: none;
        }
}